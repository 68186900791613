<template>
  <div class="app">
    <NuxtPage />
  </div>
  <v-snackbar
    v-model="show"
    absolute
    :color="type"
    timeout="1000"
    location="bottom">
    {{ message }}
  </v-snackbar>

  <AchievementPopUp />
  <SurveyPopup v-if="surveyPopup"/>
</template>

<script setup>
// snackbar
import { storeToRefs } from "pinia";
const snackbar = useSnackbarStore();
const { show, message, type } = storeToRefs(snackbar);

const popup = usePopupStore();
const {surveyPopup} = storeToRefs(popup);
// surveyPopup.value = true;

// socket
import { io } from "socket.io-client";
const socket = io(useRuntimeConfig().public.socketURL);
// socket.on("connect", () => {
//   console.log("websocket connected");
// });
socket.on("game-session", (data) => {
  // console.log("game session event", data);
  document.dispatchEvent(
    new CustomEvent(`game-session-${data.type}`, { detail: data })
  );
});
</script>
